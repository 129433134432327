.plain-table {
  border-spacing: 0;
  td {
    height: 20px;
    padding:  8px;
    @apply bg-neutral-100 border-neutral-100;
    &:first-child {
      padding-left: 0;
      border-radius: var(--tui-radius-m) 0 0 var(--tui-radius-m);
    }

    &:last-child {
      border-radius: 0 var(--tui-radius-m) var(--tui-radius-m) 0;
    }
  }

  &--bordered{
    td{
      @apply border-b-neutral-300;
      border-right: 0!important;
      border-radius:0!important;
      margin: 0!important;
    }
  }

  tr.field-has-changes{
    td{
      background: var(--tui-success-bg-hover)!important;
    }
  }

  th {
    z-index: 0!important;
    height: 20px;
    @apply bg-neutral-100;
    border-color: transparent;

    &:first-child, {
      padding-left: 0;
    }
  }

  tfoot {
    td {

      @apply bg-neutral-200 border-neutral-200;
      &:first-child {
        padding-left: 0;
        @apply rounded-l-md
      }

      &:last-child {
        @apply rounded-r-md
      }
    }
  }
}
